import {
  OverviewTransaction,
  RelatorioBoletoData,
  RelatorioTaxaConveniencia,
  RelatorioTransacoesDetalhado,
  RelatorioTransacoesGeral,
  RelatorioTransacoesResumidoAgrupado,
  ReportByDateGroupAndCategory,
} from '@/models/report';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BaseApi } from './base/base-api.service';
import { Pagination } from '@/models/pagination.model';

type FindByDateGroupAndCategoryParams = {
  categoria_id: number;
};

type RelatorioTaxaConvenienciaParams = {
  pdv_id?: string | null;
  data_inicio?: string | null;
  data_fim?: string | null;
  forma_pagamento_id?: string | null;
  status?: string | null;
  page: string | null | number;
};

@Injectable({
  providedIn: 'root',
})
export class RelatorioService extends BaseApi {
  async findByDateGroupAndCategory(
    params?: FindByDateGroupAndCategoryParams
  ): Promise<ReportByDateGroupAndCategory[]> {
    return lastValueFrom(
      this.get(`${this.url}/relatorios/data-grupo-categoria`, {
        params,
      })
    );
  }

  async findOverviewTransactions(params?: any): Promise<OverviewTransaction> {
    return lastValueFrom(
      this.get(`${this.url}/relatorios/transacoes/resumido`, {
        params,
      })
    );
  }

  async boletos(params?: any): Promise<RelatorioBoletoData[]> {
    return lastValueFrom(
      this.get(`${this.url}/relatorios/boletos`, {
        params,
      })
    );
  }

  async historicoBoleto(id: any): Promise<any[]> {
    return lastValueFrom(this.get(`${this.url}/boletos/historico/${id}`));
  }

  async transacoesGeral(params: any): Promise<RelatorioTransacoesGeral[]> {
    return lastValueFrom(
      this.get(`${this.url}/relatorios/transacoes/geral`, {
        params,
      })
    );
  }

  async detalhado(params: any): Promise<RelatorioTransacoesDetalhado[]> {
    return lastValueFrom(
      this.get(`${this.url}/relatorios/transacoes/detalhado`, {
        params,
      })
    );
  }

  async resumidoAgrupado(
    params: any
  ): Promise<RelatorioTransacoesResumidoAgrupado> {
    return lastValueFrom(
      this.get(`${this.url}/relatorios/transacoes/resumido-agrupado`, {
        params,
      })
    );
  }

  async monitoramentoTermianis(params: any): Promise<any[]> {
    return lastValueFrom(
      this.get(`${this.url}/relatorios/monitoramento-terminais`, {
        params,
      })
    );
  }

  async comissoes(params: any): Promise<any[]> {
    return lastValueFrom(
      this.get(`${this.url}/relatorios/comissoes`, {
        params,
      })
    );
  }

  async buscaRecarga(params: any): Promise<any[]> {
    return lastValueFrom(
      this.get(`${this.url}/relatorios/transacoes/busca-recarga`, {
        params,
      })
    );
  }

  async revalidacaoVE(params: any): Promise<any[]> {
    return lastValueFrom(
      this.get(`${this.url}/relatorios/transacoes/revalidacao-ve`, {
        params,
      })
    );
  }

  async buscaRecargasPendentes(): Promise<any[]> {
    return lastValueFrom(
      this.get(`${this.url}/relatorios/recargas/lista-pendentes`, {})
    );
  }

  async alteraStatusRecarga(params: any): Promise<any[]> {
    return lastValueFrom(
      this.post(`${this.url}/relatorios/recargas/altera-status-recarga`, {
        ...params,
      })
    );
  }

  async vendasPorPDV(params: any): Promise<any[]> {
    return lastValueFrom(
      this.get(`${this.url}/relatorios/transacoes/vendas-por-pdv`, { params })
    );
  }

  async extratoPdv(params: any) {
    return lastValueFrom(
      this.get(`${this.url}/relatorios/extratos`, {
        params,
        responseType: 'blob',
      })
    );
  }

  async taxaConveniencia(
    params: RelatorioTaxaConvenienciaParams
  ): Promise<Pagination<RelatorioTaxaConveniencia>> {
    return lastValueFrom(
      this.get(`${this.url}/relatorios/transacoes/taxa-conveniencia`, {
        params,
      })
    );
  }

  get reportDescription() {
    const currentDateTime = new Date().toLocaleDateString('pt-br', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });

    return `Servlot - ${currentDateTime}`;
  }
}
