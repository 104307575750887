export enum Permissao {
  PDV_ALTERAR_STATUS = 'PDV_ALTERAR_STATUS',
  PDV_CAD_EDIT = 'PDV_CAD_EDIT',
  PDV_LISTA = 'PDV_LISTA',
  REL_BOLETOS = 'REL_BOLETOS',
  REL_DATA_GRUPO_CAT = 'REL_DATA_GRUPO_CAT',
  REL_FIN_CELULAR = 'REL_FIN_CELULAR',
  REL_FIN_GERAL = 'REL_FIN_GERAL',
  REL_FIN_QRCODE = 'REL_FIN_QRCODE',
  REL_FIN_SPTRANS = 'REL_FIN_SPTRANS',
  REL_FIN_TOPSP = 'REL_FIN_TOPSP',
  REL_LIMITE_PDV = 'REL_LIMITE_PDV',
  REL_TRANS_DETALHADO = 'REL_TRANS_DETALHADO',
  REL_TRANS_GERAL = 'REL_TRANS_GERAL',
  REL_TRANS_RESUMIDO = 'REL_TRANS_RESUMIDO',
  REL_TRANS_RESUMIDO_AGRUPADO = 'REL_TRANS_RESUMIDO_AGRUPADO',
  REL_COMISSOES = 'REL_COMISSOES',
  TERMINAIS_CAD_EDIT = 'TERMINAIS_CAD_EDIT',
  TERMINAIS_FW_LISTA = 'TERMINAIS_FW_LISTA',
  TERMINAIS_LISTA = 'TERMINAIS_LISTA',
  TERMINAIS_MENSAGENS = 'TERMINAIS_MENSAGENS',

  REL_FATURAMENTOS = 'REL_FATURAMENTOS',
  TERMINAIS_TRANSFERENCIA = 'TERMINAIS_TRANSFERENCIA',
  BLOQ_FIN = 'BLOQ_FIN',
  ESTORNAR_PIX = 'ESTORNAR_PIX',
  REL_MONITORAMENTO_TERMINAIS = 'REL_MONITORAMENTO_TERMINAIS',
  LANCAMENTOS_FINANCEIROS = 'LANCAMENTOS_FINANCEIROS',
  REL_TRANS_VENDAS_POR_PDV = 'REL_TRANS_VENDAS_POR_PDV',
  REL_HISTORICO_PREPAGO = 'REL_HISTORICO_PREPAGO',
  REL_EXTRATOS_PDV = 'REL_EXTRATOS_PDV',
  DASHBOARD = 'DASHBOARD',

  ADM_PROCESSAMENTO_CNAB = 'ADM_PROCESSAMENTO_CNAB',
  ADM_STATUS_RECARGA = 'ADM_STATUS_RECARGA',
  ADM_ESTORNO_PIX = 'ADM_ESTORNO_PIX',
  ADM_MENSAGEM_TERMINAL = 'ADM_MENSAGEM_TERMINAL',
  ADM_BANCOS_PDV = 'ADM_BANCOS_PDV',
  CONCILIACOES_PIX = 'CONCILIACOES_PIX',
  PDV_PARTIAL_LIST = 'PDV_PARTIAL_LIST',
  TERMINAIS_PARTIAL_LIST = 'TERMINAIS_PARTIAL_LIST',

  ADM_COMISSAO_PADRAO = 'ADM_COMISSAO_PADRAO',
  ADM_PERFIS_PERMISSOES = 'ADM_PERFIS_PERMISSOES',
  ADM_TERMINAIS_ATUALIZACAO = 'ADM_TERMINAIS_ATUALIZACAO',
  ADM_TERMINAIS_FIRMWARE = 'ADM_TERMINAIS_FIRMWARE',
  ADM_FERIADOS = 'ADM_FERIADOS',
  ADM_DIAS_FATURAMENTO_EDIT = 'ADM_DIAS_FATURAMENTO_EDIT',
  ADM_DIAS_FATURAMENTO_LISTA = 'ADM_DIAS_FATURAMENTO_LISTA',
  ADM_USUARIOS_LISTA = 'ADM_USUARIOS_LISTA',

  REL_REVALIDACAO_VE = 'REL_REVALIDACAO_VE',
  BUSCAR_RECARGAS = 'BUSCAR_RECARGAS',
  EXTRATO_PDV = 'EXTRATO_PDV',
  REL_FIN_FATURAMENTO_RECOLHE = 'REL_FIN_FATURAMENTO_RECOLHE',
  ADM_HIPERCAP = 'ADM_HIPERCAP',
  REL_TAXA_CONVENIENCIA = 'REL_TAXA_CONVENIENCIA',
}
